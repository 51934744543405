<template>
    <div style="min-height:200px">

        <b-card>
            <app-view-table title="Easyflex " :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" :fields="fields" :filterOptions="status_options" isSearch :isFilterOptions="false"   :topFiltersCount="1">
                <template v-slot:menu-items="cell">
                    <b-dropdown-item @click="syncNow(filters.list_type, cell.data.item.item_id)">Sync NOW</b-dropdown-item>
                </template>
                <template v-slot:filter-col-1>
                    <b-button-group  size="sm">
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.list_type !== 1, 'app-list-button-selected':  filters.list_type === 1 }" @click="setListType(1)">{{trans('employee', 5)}}</b-btn>
                        <b-btn class="app-input-min-height app-list-button" v-bind:class="{ 'btn-secondary-grey': filters.list_type !== 2, 'app-list-button-selected':  filters.list_type === 2 }" @click="setListType(2)">{{trans('client', 5)}}</b-btn>
                    </b-button-group> 
                </template>

            </app-view-table>
        </b-card>  

    </div>
</template>
<script>

import axios from "@axios";
import UserSettings from '@core/scripts/UserSettings';

export default {


    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            loading_list: false,
            filters: {
                page: 1,
                per_page: 10,
                 sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {
                    statuses: {type: 'list', changed: 0, values: [], function: "getEasyflexStatus", parameters: null, widthClass:'app-width-min-350', getList: true},
                    types: {type: 'list', changed: 0, values: [], function: "getEasyflexType", parameters: null, widthClass:'app-width-min-350', getList: true},
                },
                search: "",
                list_type:1,
                status: 1,
                changed: 0,
                search: ''
            },

            status_options: [
                { value: 1, name: this.trans('settings-to-update',207) },
                { value: 2, name: this.trans('settings-update',207) },
            ],
            fields: [
                //{ key: "id_easyflex", label: this.trans('settings-id',207), thStyle: { "min-width": "100px", "max-width": "100px"}, visible: true },
                { key: "name", label: this.trans('flexworker', 182), thStyle: { "min-width": "200px", "max-width": "200px"}, visible: true },
                { key: "status", label:  this.trans('status',3), thStyle: { "min-width": "200px", "max-width": "200px"}, visible: true, filtering: true, filtering_name: 'statuses' },
                { key: "type", label:  this.trans('type',182), thStyle: { "min-width": "200px", "max-width": "200px"}, visible: true, filtering: true, filtering_name: 'types' },
                { key: "event_date", label: this.trans('settings-event-date',207), thStyle: { "min-width": "210px", "max-width": "210px" }, visible: true },
                { key: "message", label: this.trans('settings-message',207), thStyle: {  width: "100%"  }, visible: true },
            ],
            items: [],
            userSettings: new UserSettings(),
        };
    },

    methods: {
        getList: function() {
            this.loading_list= true,
            axios
                .post("settings/easyflex/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                   
    
                })
                .catch((error) => {
                    console.error(`error : ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });
        },

        syncNow: function(type, id) {
            this.loading_list= true;
            axios
                .post("settings/easyflex/syncNow", {
                    item_type: type,
                    item_id: id
                })
                .then((res) => {
                    
                })
                .catch((error) => {
                    console.error(`error : ${error}`);
                })
                .finally(() => {
                    this.getList();
                });
        },

        setListType(type){
            this.filters.list_type = type;
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 18);
        },

        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));
            
            if(getData == undefined || getData.easyflexQueueList == undefined){
                await this.userSettings.saveSettings(this.filters, 18);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
              
            var sFilters = getData.easyflexQueueList
            this.filters.list_type = sFilters.list_type;
            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting; 
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;        
        },

        onCreateThis() { 
            
            this.loadSettings();
            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
                
            });

            

            this.$watch('filters.list_type',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();

                    if(newVal == 1){
                        this.fields[0].label = this.trans('flexworker', 182);
                    } else if(newVal == 2){
                        this.fields[0].label = this.trans('core-client', 3);
                    }
                    this.saveSettings();
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
        },
    },
 
};
</script>